import * as R from "ramda";

import resourceOperations from "./resourceOperations";

const operation = resourceOperations({
  basePath: "/api/requirements",
  baseKey: ["requirements"],
});

export const useRequirement = (id, opts = {}) => operation.useRead(id, opts);
export const useRequirements = (opts = {}) => operation.useRead(null, opts);
export const useRequirementsByID = (ids = [], opts = {}) =>
  useRequirements({
    ...opts,
    select: R.filter((r) => ids.includes(r.id)),
  });
