import React, { useCallback } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom-v5-compat";

import Footer, { useErrors } from "components/projects/Footer";
import useValidationFieldsForRequirement from "components/projects/requirement/useValidationFieldsForRequirement";
import useIsDirectApply from "components/projects/useIsDirectApply";
import { useAnswerContext } from "containers/withAnswerContext";
import { useOnNext } from "hooks/useOnNext";
import { selectZoning } from "reducers/projects";
import routes from "utils/routes";
import { getZoningPermissionCategory } from "utils/zoning";

const RequirementFooter = ({ ...props }) => {
  const navigate = useNavigate();
  const answerContext = useAnswerContext();
  const { record: project = {}, context: requirement = {} } = answerContext;
  const { requirementOrDirect } = useIsDirectApply();
  const { fieldIDs, contextuallyRequiredFieldIDs } = useValidationFieldsForRequirement(
    project,
    requirement,
  );

  const onNext = useOnNext({
    fieldIDs,
    contextuallyRequiredFieldIDs,
    nextPage: `/projects/${project.id}/${requirementOrDirect}/${requirement.slug}/submit`,
    nextPageType: "PATH",
  });

  const onBack = useCallback(() => {
    navigate(routes.projectHandoff(project.id));
  }, [navigate, project.id]);

  const disableNext = useSelector((state) =>
    R.compose(R.equals("prohibited"), getZoningPermissionCategory, selectZoning)(state, project),
  );
  const errorsProps = useErrors({ fieldIDs });

  return (
    <Footer {...props} {...errorsProps} onNext={onNext} onBack={onBack} disableNext={disableNext} />
  );
};
export default RequirementFooter;
