import * as R from "ramda";

import { useCurrentTenantInfo } from "contexts/currentTenant";

import resourceOperations from "../resourceOperations";

const operation = resourceOperations({
  basePath: "/api/config/tenants",
  baseKey: ["config", "tenants"],
  dataKey: "tenant",
});

export const useTenant = (id, opts = {}) => operation.useRead(id, opts);
export const useCurrentTenant = (opts = {}) => {
  const { slug } = useCurrentTenantInfo();
  return useTenant(slug, opts);
};
export const useTenantsList = (opts = {}) => operation.useRead("list", opts);
export const useTenantsIndex = (opts = {}) => operation.useRead(null, opts, R.values);

export const useCreateTenant = (opts = {}) => operation.useCreate(null, opts);
export const useUpdateTenant = (id, opts = {}) => operation.useUpdate(id, opts);
export const useDestroyTenant = (opts = {}) => operation.useDestroy(opts);
