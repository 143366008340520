import * as R from "ramda";
import { useInfiniteQuery } from "react-query";

import ocClient from "services/ocClient";
import { addFilterParam } from "utils/urls";

import resourceOperations from "./resourceOperations";

export const useProjectTransactions = (projectID, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/transactions`,
    baseKey: "transactions",
  }).useRead(null, { staleTime: 0, ...opts });

export const useProjectRequirementTransactions = (projectID, requirementID, opts = {}) =>
  useProjectTransactions(projectID, {
    ...opts,
    select: R.pipe(
      R.filter(R.where({ requirement_ids: R.contains(Number(requirementID)) })),
      opts.select || R.identity,
    ),
  });

export const useProjectTransaction = (projectID, id, opts = {}) =>
  useProjectTransactions(projectID, { ...opts, select: R.find(R.propEq("id", Number(id))) });

export const useCreateTransaction = (projectID, invoiceID, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/invoices/${invoiceID}/transactions`,
    baseKey: "transactions",
  }).useCreate(null, opts);

export const useCreateRefund = (transactionID, opts = {}) =>
  resourceOperations({
    basePath: `/api/transactions/${transactionID}/refunds`,
    baseKey: "transactions",
  }).useCreate(null, opts);

export const useTransactionDetails = (filter, opts = {}) =>
  resourceOperations({
    basePath: addFilterParam("/api/admin/transactions/details", filter),
    baseKey: ["transactionDetails", filter],
  }).useRead(null, opts);

const BATCH_SIZE = 100;
export const useInfiniteTransactionsList = (filters = {}, opts = {}) =>
  useInfiniteQuery({
    queryKey: ["admin", "transactionDetails", "list", filters],
    queryFn: async ({ pageParam = 0 }) => {
      const offset = pageParam * BATCH_SIZE;
      const fetchedData = await ocClient.get("/api/admin/transactions/details", {
        params: { ...filters, offset },
      });
      return fetchedData;
    },
    initialPageParam: 0,
    getNextPageParam: (_lastGroup, groups) => groups.length,
    refetchOnWindowFocus: false,
    staleTime: 500,
    ...opts,
  });
