import { useMemo } from "react";
import * as R from "ramda";

import resourceOperations from "./resourceOperations";

const operation = resourceOperations({
  basePath: "/api/fields",
  baseKey: ["fields"],
});

export const useField = (id, opts = {}) => operation.useRead(id, opts);

export const useFields = (opts = {}) => operation.useRead(null, opts);
export const useFieldsByID = (ids = [], opts = {}) =>
  useFields({
    ...opts,
    select: R.filter((r) => ids.includes(r.id)),
  });

export const useFieldWithChildren = (id, opts = {}) => {
  const { data: field, ...queryProps } = useField(id, opts);
  const { data: children = [] } = useFieldsByID(field?.child_ids, {
    enabled: field && field.child_ids.length > 0,
  });

  const fieldWithChildren = useMemo(() => {
    if (!field) return null;
    if (field.child_ids.length && !children.length) return null;
    return R.assoc("children", children, field);
  }, [children, field]);

  return { ...queryProps, data: fieldWithChildren };
};

export const useFieldBySlug = (slug, opts = {}) =>
  useFields(
    {
      ...opts,

      select: R.find(R.propEq("slug", slug)),
    },
    R.head,
  );

export const useFieldsBySlug = (slugs, opts = {}) =>
  useFields(
    {
      ...opts,

      select: R.filter((field) => R.contains(field.slug, slugs)),
    },
    R.head,
  );
