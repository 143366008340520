import React, { Suspense, lazy } from "react";

import { SuperuserFeature } from "components/privilegedUserFeatures";
import useRegisteredHotKey from "hooks/useRegisteredHotKey";

// Prevent ace from being included in main bundle
const Inspector = lazy(() => import("components/projects/Inspector"));

const MaybeInspector = (props) => (
  // eslint-disable-next-line react/destructuring-assignment
  <Suspense fallback={props.children}>
    <Inspector {...props} />
  </Suspense>
);

const ProjectHotKeys = () => {
  useRegisteredHotKey(
    "i",
    ({ setContainer }) => {
      setContainer(MaybeInspector);
    },
    {
      section: "Project",
      description: "Inspect project",
    },
  );

  useRegisteredHotKey(
    "n",
    () => {
      const saveButton = document.querySelector("[data-save-button]");
      if (saveButton) saveButton.click();
    },
    {
      section: "Project",
      description: "Next page",
    },
  );

  useRegisteredHotKey(
    "p",
    () => {
      const button = document.querySelector("[data-back-button]");
      if (button) button.click();
    },
    {
      section: "Project",
      description: "Next page",
    },
  );
  return null;
};

const MaybeProjectHotKeys = () => (
  <SuperuserFeature>
    <ProjectHotKeys />
  </SuperuserFeature>
);

export default MaybeProjectHotKeys;
