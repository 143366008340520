import React from "react";
import classnames from "classnames";
import * as R from "ramda";
import { useSelector } from "react-redux";

import { ButtonDiv } from "components/accessibility/Div";
import { useAnswerContext } from "containers/withAnswerContext";
import { useEntitiesListForType } from "queries/entities";
import { getAnswerForField } from "reducers/answerContexts";
import { selectSystemFieldBySlug } from "reducers/fields";
import { isPresent } from "utils/func";

import styles from "./ChangesetEditRequirementsForm.scss";

const Checkbox = ({ onChange, value }) => {
  const toggle = () => onChange(!value);

  return (
    <ButtonDiv
      className={classnames(styles.checkbox, { [styles.activeCheckbox]: value })}
      onClick={toggle}
    >
      <div data-disable={!value} />
      <div data-enable={value} />
    </ButtonDiv>
  );
};

const RequirementOverride = ({ requirement, onChange, value }) => {
  const { record } = useAnswerContext();

  const overrideValue = R.prop(requirement.identifier, value || {});
  const derivedValue = R.includes(requirement.id, R.propOr([], "requirement_ids", record));
  const checkboxValue = overrideValue === undefined ? derivedValue : overrideValue;

  return (
    <div className={styles.requirementRow} data-requirement-override={requirement.key}>
      <div className={styles.checkboxGroup}>
        <Checkbox onChange={onChange} value={checkboxValue} />
      </div>
      <div>{requirement.name}</div>
    </div>
  );
};
const DepartmentRequirements = ({ value, department, requirements, updateValueFor }) => (
  <div className={styles.departmentSection}>
    <h3>{department.name}</h3>
    <div className={styles.requirementsList}>
      {requirements.map((requirement) => (
        <RequirementOverride
          key={requirement.id}
          requirement={requirement}
          onChange={updateValueFor(requirement.identifier)}
          value={value}
        />
      ))}
    </div>
  </div>
);

const EditRequirementsOverridesField = ({ requirements }) => {
  const { record: changeset, onChange, onSave } = useAnswerContext();

  const field = useSelector((s) => selectSystemFieldBySlug(s, "overrides"));
  const value = getAnswerForField(changeset, field);
  const onChangeField = R.curry(onChange)(field);

  const updateValueFor = R.curry((identifier, overrideValue) => {
    if (isPresent(overrideValue)) {
      onChangeField(R.assoc(identifier, overrideValue, value));
      onSave(field);
    } else {
      onChangeField(R.dissoc(identifier, value));
      onSave(field);
    }
  });

  const byDepartmentID = R.groupBy(R.prop("department_id"), requirements);
  const departmentIDs = R.keys(byDepartmentID).map((id) => Number(id));
  const { data: departments = [] } = useEntitiesListForType("Department", {
    select: R.filter((d) => R.includes(R.prop("id", d), departmentIDs)),
  });

  return (
    <div className={styles.container}>
      <div>
        {departments.map((department) => (
          <DepartmentRequirements
            key={department.identifier}
            department={department}
            requirements={byDepartmentID[department.id]}
            updateValueFor={updateValueFor}
            value={value}
          />
        ))}
      </div>
    </div>
  );
};

export default EditRequirementsOverridesField;
