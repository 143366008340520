import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as R from "ramda";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchInitialState,
  fetchProject,
  saveAnswer,
  setAnswer,
  setProjectSavePending,
  updateValidationError,
} from "actions";
import { FullScreenLoader } from "components/Loader";
import { usePusherSubscription } from "hooks/pusher";
import useParam from "hooks/useParam";
import invalidations from "queries/invalidations";
import { selectIsProjectSavePending } from "reducers/network";
import { selectIsProjectLoaded, selectProjectByID } from "reducers/projects";
import { selectTenantVersionNumber } from "reducers/tenant";
import createAnswerContext from "utils/AnswerContext";
import { hoc } from "utils/hoc";

const AnswerContext = React.createContext({});
export const AnswerContextProvider = ({ value, children }) => (
  <AnswerContext.Provider value={value}>{children}</AnswerContext.Provider>
);

const getCurrentErrors = R.pathOr({}, ["record", "errors"]);

export const ReduxAnswerContextProvider = ({ answerContext, children }) => {
  const dispatch = useDispatch();

  const pending = useSelector(selectIsProjectSavePending);
  const setPending = useCallback((val) => dispatch(setProjectSavePending(val)), [dispatch]);
  const [nextAction, setNextAction] = useState();

  const onSave = useCallback(
    (field) => {
      dispatch(saveAnswer({ field, answerContext }));
    },
    [answerContext, dispatch],
  );

  const onChange = useCallback(
    (field, value) => {
      dispatch(setAnswer({ field, value, answerContext }));
    },
    [answerContext, dispatch],
  );

  const setError = useCallback(
    (field, error) => {
      dispatch(
        updateValidationError({
          answerContext,
          errors: R.assoc(field.id, error, getCurrentErrors(answerContext)),
        }),
      );
    },
    [answerContext, dispatch],
  );

  const clearError = useCallback(
    (field) => {
      dispatch(
        updateValidationError({
          answerContext,
          errors: R.dissoc(field.id, getCurrentErrors(answerContext)),
        }),
      );
    },
    [answerContext, dispatch],
  );

  const memoizedContext = useMemo(
    () => ({
      ...answerContext,
      onSave,
      onChange,
      setError,
      clearError,
      setPending,
      pending,
      nextAction,
      setNextAction,
      type: "REDUX",
    }),
    [
      answerContext,
      pending,
      setPending,
      nextAction,
      setNextAction,
      onSave,
      onChange,
      setError,
      clearError,
    ],
  );

  return <AnswerContextProvider value={memoizedContext}>{children}</AnswerContextProvider>;
};
// HOC to connect an answer context to redux actions
const WithReduxAnswerContextProvider = ({ Component, answerContext, ...props }) => (
  <ReduxAnswerContextProvider answerContext={answerContext}>
    <Component {...props} />
  </ReduxAnswerContextProvider>
);
export const withReduxAnswerContextProvider = hoc(WithReduxAnswerContextProvider);

// HOC to inject another context into AnswerContext
export const withMappedAnswerContext = (mapProps) => {
  const WithMappedAnswerContext = ({ Component, ...props }) => {
    const answerContext = useAnswerContext();
    const withContext = R.assoc("context", mapProps(props, answerContext), answerContext);

    return (
      <AnswerContextProvider value={withContext}>
        <Component answerContext={withContext} {...props} />
      </AnswerContextProvider>
    );
  };
  return hoc(WithMappedAnswerContext);
};

export const useAnswerContext = () => useContext(AnswerContext);

const AnswerContextConsumer = ({ Component, ...props }) => {
  const answerContext = useAnswerContext();
  return <Component answerContext={answerContext} {...props} />;
};
export const withAnswerContextConsumer = hoc(AnswerContextConsumer);

export const RootAnswerContextProvider = ({ children }) => {
  const projectID = useParam("projectID");
  const { isLoaded, project = {} } = useSelector((state) => ({
    isLoaded: selectIsProjectLoaded(state, projectID),
    project: selectProjectByID(state, projectID),
  }));
  const { version_number: projectVersion } = project;
  const activeVersion = useSelector(selectTenantVersionNumber);
  const answerContext = createAnswerContext(project);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded) return;
    dispatch(fetchProject({ id: projectID }, { from: __filename }));
  }, [isLoaded, projectID, dispatch]);

  useEffect(() => {
    if (!isLoaded) return;
    if (projectVersion !== activeVersion)
      dispatch(fetchInitialState({ versionNumber: projectVersion }));
  }, [isLoaded, projectVersion, activeVersion, dispatch]);

  const queryClient = useQueryClient();
  const invalidate = useCallback(() => {
    invalidations.projects(queryClient, projectID);
  }, [projectID, queryClient]);
  const pusherChannel = projectID ? `private-project-${projectID}` : null;
  usePusherSubscription(
    pusherChannel,
    "update",
    useCallback(
      ({ id }) => {
        dispatch(fetchProject({ id }, { from: [__filename, "pusher: update"] }));
        invalidate();
      },
      [dispatch, invalidate],
    ),
  );
  usePusherSubscription(
    pusherChannel,
    "log-entry-created",
    useCallback(
      ({ projectID: id }) => {
        dispatch(fetchProject({ id }, { from: [__filename, "pusher: log-entry-created"] }));
        invalidate();
      },
      [dispatch, invalidate],
    ),
  );

  if (!isLoaded) return <FullScreenLoader label="RootAnswerContextProvider" />;

  return (
    <ReduxAnswerContextProvider answerContext={answerContext}>
      {children}
    </ReduxAnswerContextProvider>
  );
};
