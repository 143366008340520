import * as R from "ramda";
import { SubmissionError } from "redux-form";

import ocClient from "services/ocClient";
import NetworkError from "utils/NetworkError";
import { X_TENANT_VERSION } from "utils/httpHeaderNames";
import param from "utils/objectToQueryString";
import { addFilterParam } from "utils/urls";

function handleAxiosError(error) {
  const { response } = error;
  if (response.status === 500) throw new NetworkError(`Server error`, response);
  if (response.status === 401) throw new NetworkError(`Not Authenticated`, response);
  if (response.status === 488) throw new NetworkError(`CSRF error`, response);
  if (response.status === 422) throw new SubmissionError(response.data);
  // why not just `throw error`?
  throw new NetworkError(`Network error: ${response.status}`, response, response.data?.errors);
}

const get = async (path, { optionsTransform = R.identity } = {}) => {
  let response;
  try {
    response = await ocClient.get(path, optionsTransform({}));
  } catch (error) {
    handleAxiosError(error);
  }
  return response.data;
};

const convertUndefinedToNull = (k, v) => (typeof v === "undefined" ? null : v);

const requestMutation = async (
  path,
  method,
  payload = {},
  { optionsTransform = R.identity, rawResponse = false } = {},
) => {
  let response;
  try {
    response = await ocClient(
      optionsTransform({
        url: path,
        method,
        data: JSON.stringify(payload, convertUndefinedToNull),
      }),
    );
  } catch (error) {
    await handleAxiosError(error);
  }
  return rawResponse ? response : response.data;
};

function download(url) {
  return ocClient.get(url);
}

export const fetchInitialState = (url) =>
  get(url, {
    optionsTransform: R.compose(
      // try to match the prefetch request
      R.over(R.lensProp("headers"), R.compose(R.assoc("accept", "*/*"))),
    ),
  });

const versionedAPI = (versionNumber) => {
  const addVersionHeaders = R.over(R.lensProp("optionsTransform"), (fn = R.identity) =>
    R.compose(R.over(R.lensProp("headers"), R.assoc(X_TENANT_VERSION, versionNumber)), fn),
  );

  const versionedRequestMutation =
    (method) =>
    (path, payload = {}, options = {}) =>
      requestMutation(path, method, payload, addVersionHeaders(options));

  const api = {
    get(path, options = {}) {
      return get(path, addVersionHeaders(options));
    },
    post: versionedRequestMutation("POST"),
    put: versionedRequestMutation("PUT"),
    delete: versionedRequestMutation("DELETE"),
  };

  return {
    createProject: (project = {}) => api.post(`/api/projects`, project),
    fetchFields: (ids) => api.get(`/api/fields.json?${param({ ids })}`),

    fetchRequirementApplications: (filter) =>
      api.get(addFilterParam("/api/admin/requirement_applications", filter)),

    fetchRequirementApplication: (id) => api.get(`/api/admin/requirement_applications/${id}`),

    addRequirementApplicationNote: (requirementApplicationID, text) =>
      api.put(`/api/admin/requirement_applications/${requirementApplicationID}/add_note`, { text }),

    fetchTenantSummary: () => api.get("/api/admin/tenants/summary"),

    fetchRequirement: (id) => api.get(`/api/requirements/${id}`),

    fetchAnalytics: (type, to, from) =>
      api.get(`/api/admin/analytics/overview?${param({ type, to, from })}`),

    admin: {
      submitRefund: (refund) =>
        api.post(`/api/admin/transactions/${refund.transaction_id}/refunds`, {
          refund,
        }),

      fetchUsers: () => api.get("/api/admin/users"),
      fetchUser: (id) => api.get(`/api/admin/users/${id}`),
      createUser: (user) => api.post("/api/admin/users", { user }),
      updateUser: (user) => api.put(`/api/admin/users/${user.id}`, { user }),

      updateRequirementApplication: (requirement_application) =>
        api.put(`/api/admin/requirement_applications/${requirement_application.id}`, {
          requirement_application,
        }),
      processRequirementApplication: (id) =>
        api.put(`/api/admin/requirement_applications/${id}/mark_as_processed`),
      approveRequirementApplication: (id, values) =>
        api.put(`/api/admin/requirement_applications/${id}/mark_as_approved`, values),
      rejectRequirementApplication: (id, values) =>
        api.put(`/api/admin/requirement_applications/${id}/mark_as_rejected`, values),
      revokeRequirementApplication: (id, note) =>
        api.put(`/api/admin/requirement_applications/${id}/mark_as_revoked`, {
          note,
        }),
      changesRequiredRequirementApplication: (id, values) =>
        api.put(`/api/admin/requirement_applications/${id}/mark_as_changes_required`, values),
      downloadTransactions: (params) =>
        download(`/api/admin/transactions/details.csv?${param(params)}`),
    },

    config: {
      fetchRoles: () => api.get(`/api/config/roles`),

      runJob: (type) => api.post("/api/config/run_job", { type }),
      fetchAutoCompletions: () => api.get("/api/config/ui/auto_completions"),
      fetchExpressionGraph: () => api.get("/api/config/ui/expression_graph"),

      fetchSpec: () => api.get("/api/config/specs"),
      fetchSpecResults: () => api.get("/api/config/specs/results"),
      saveSpec: (source) => api.post("/api/config/specs", { source }),
      runSpec: (_source) => api.post("/api/config/specs/run"),

      fetchVersions: () => api.get(`/api/config/versions`),
      validateExpression: ({ id, type, context_type, context_id, expression }) =>
        api.post(`/api/config/expressions/validate`, {
          id,
          type,
          context_type,
          context_id,
          expression,
        }),
    },
  };
};

const post = (path, payload = {}, options = {}) => requestMutation(path, "POST", payload, options);
const put = (path, payload = {}, options = {}) => requestMutation(path, "PUT", payload, options);
export const unversionedAPI = {
  admin: {
    project: {
      fetch: (id) => get(`/api/admin/projects/${id}`),
      explain: (id, { identifier, rawExpression }) => {
        const params = param({
          identifier,
          raw_expression: rawExpression,
        });
        return get(`/api/admin/projects/${id}/explain?${params}`);
      },
    },
    exportData: (exportType, format, params) =>
      post(`/api/admin/exports/export`, { type: exportType, format, ...params }),
  },
  project: {
    fetch: (id) => get(`/api/projects/${id}`),
    fetchPDF: (id) => download(`/api/projects/${id}.pdf`),

    pushToAccela: (projectID, requirementID) =>
      put(
        `/api/projects/${projectID}/push_to_accela?${param({
          requirement_id: requirementID,
        })}`,
        {},
        { rawResponse: true },
      ),
    createRenewal: (projectID, requirementID) =>
      post(
        `/api/projects/${projectID}/renew?${param({
          requirement_id: requirementID,
        })}`,
      ),
    saveAnswers: (project, answers) =>
      put(`/api/projects/${project.id}/save_answers`, {
        answers,
      }),
  },

  fetchWrappedVaultToken: () => post("/api/vault/tokens"),
  downloadRequirementApplicationPDF: (requirementApplicationID) =>
    download(`/api/requirement_applications/${requirementApplicationID}.pdf`),

  downloadIssuedRequirementPDF: (requirementApplicationID) =>
    download(`/api/requirement_applications/${requirementApplicationID}/issued_requirement.pdf`),

  updateUser: (params) => put(`/api/applicants/${R.prop("id", params)}`, { user: params }),

  updatePassword: ({ id, oldPassword, password }) =>
    post("/api/applicants/update_password", {
      id,
      user: {
        id,
        oldPassword,
        password,
      },
    }),

  fetchMyProjects: () => get("/api/projects"),
};

export default versionedAPI;
