/**
 * This module handles the key generation and invalidation for our react-query based state mangement system.
 *
 * The data structure is hierachal (ex: project -> requirementApplications -> reviews) which means that
 * invalidating a project invalidates all related data.
 *
 * To add a new path you will probably only need to add it to the general paths section and the admin/ basic
 * invalidations will be auto generated. In more complex or non-standard cases you can also write each of these
 * parts yourself or customize them.
 *
 * Usage:
 *   import keys from "queries/keys";
 *   keys.projects(1);
 *   keys.admin.projects(1);
 *
 * This covers use cases like:
 *   get/load one thing
 *   get/load all things of type
 *   all the above but for admin data structures
 */

// base paths to be used with react-query. When id null they return the root path.
const projectPath = (projectID) => ["projects", projectID];
const requirementApplications = (pID, id = null) => [
  ...projectPath(pID),
  "requirementApplications",
  id,
];
const keys = {
  projects: projectPath,
  projectScope: (id) => [...projectPath(id), "scope"],
  projectValidate: (id, params) => [...projectPath(id), "validate", params],
  users: (id) => ["users", id],
  geoms: (ids) => ["geoms", ids],

  requirementApplications,
  requirementApplicationReviews: (pID, raID, id = null) =>
    requirementApplications(pID, raID).concat(["reviews", id]),
  requirementApplicationRenewals: (pID, id = null) =>
    requirementApplications(pID, id).concat(["renewals"]),
  requirementApplicationAssets: (pID, raID) =>
    requirementApplications(pID, raID).concat(["assets"]),
  requirementApplicationUsers: (pID, raID) =>
    requirementApplications(pID, raID).concat(["assigned_users"]),
};

// each path omits the last paramter if it wasn't passed to handle root level cases (ex: /projects (root) vs /projects/1 (individual record))
Object.keys(keys).forEach((k) => {
  const orgPathFn = keys[k];
  keys[k] = (...args) => {
    const path = orgPathFn(...args);
    if (path[path.length - 1] === null) path.pop();
    return path;
  };
});

// prefix all admin paths with "admin" but reuse general paths(and take the same args) which are setup above
const admin = {};
Object.entries(keys).forEach(([type, pathFn]) => {
  admin[type] = (...args) => ["admin"].concat(pathFn(...args));
});

export const keyNames = Object.keys(keys);
keys.admin = admin;
export default keys;
