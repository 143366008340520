import * as R from "ramda";

import resourceOperations from "../resourceOperations";

const operation = resourceOperations({
  basePath: "/api/admin/users",
  baseKey: "users",
  versioned: false,
});

export const useUsers = (opts = {}) => operation.useRead(null, opts, R.values);
