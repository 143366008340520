import * as R from "ramda";
import { useQueryClient } from "react-query";

import invalidations from "./invalidations";
import resourceOperations from "./resourceOperations";

const operationsFor = (projectID) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/invoices`,
    baseKey: "invoices",
    dataKey: "invoice",
  });

export const useProjectInvoices = (projectID, opts = {}) =>
  operationsFor(projectID).useRead(null, { staleTime: 0, ...opts });

export const useProjectRequirementInvoices = (
  projectID,
  requirementID,
  { select = R.T, ...opts } = {},
) =>
  useProjectInvoices(projectID, {
    ...opts,
    select: R.filter(
      R.allPass([R.where({ requirement_ids: R.contains(Number(requirementID)) }), select]),
    ),
  });

export const useProjectInvoice = (projectID, id, opts = {}) =>
  useProjectInvoices(projectID, { ...opts, select: R.find(R.propEq("id", Number(id))) });

export const useUpdateProjectInvoice = (
  projectID,
  id,
  { onSuccess = R.identity, ...opts } = {},
) => {
  const queryClient = useQueryClient();
  return operationsFor(projectID).useUpdate(id, {
    onSuccess(updatedInvoice) {
      invalidations.projects(queryClient, projectID);
      onSuccess(updatedInvoice);
    },
    ...opts,
  });
};
