import resourceOperations from "./resourceOperations";

export const useMessages = (projectID, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
    baseKey: ["messages", projectID],
  }).useRead(null, { staleTime: 0, ...opts });

export const useMessage = (projectID, messageID, opts = {}) =>
  useMessages(projectID, {
    select: (messages) => messages.find((m) => m.id === messageID),
    ...opts,
  });

export const useSendMessage = (projectID, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
    baseKey: "messages",
    dataKey: "message",
  }).useCreate(null, opts);

export const useSubscribeToMessages = (projectID, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
  }).useCreate("subscribe", opts);

export const useSubscriptionAssignments = (projectID, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
    baseKey: ["messages", projectID],
  }).useRead("subscriptions", opts);

export const useAssignedUserIDs = (projectID, opts = {}) =>
  useSubscriptionAssignments(projectID, {
    select: (subscriptions) => subscriptions.map((s) => s.user_id),
    ...opts,
  });

export const useAssignSubscriptions = (projectID, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
  }).useUpdate("update_subscriptions", opts);

export const useUnsubscribe = (projectID, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
  }).useCreate("unsubscribe", opts);

export const useMarkMessageRead = (projectID, messageID, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
    baseKey: "messages",
  }).useUpdate([messageID, "mark_as_read"], {
    ...opts,
    invalidates: ["admin", "messages", "subscribed"],
  });

export const useMarkAllMessagesProcessed = (projectID, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
  }).useUpdate("mark_all_as_processed", {
    ...opts,
    invalidates: ["admin", "messages", "subscribed"],
  });

export const useLatestMessage = (projectID, opts = {}) =>
  useMessages(projectID, {
    select: (allMessages) => {
      const messages = allMessages.filter((m) => m.from_applicant);
      const byDate = messages.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      const unprocessed = messages.filter((m) => !m.read_at);
      return unprocessed.length ? unprocessed[0] : byDate[0];
    },
    ...opts,
  });

export const useAttachments = (projectID, opts = {}) =>
  useMessages(projectID, {
    select: (messages) =>
      messages.reduce(
        (acc, m) => [
          ...acc,
          ...m.attachments.map((a) => ({
            ...a,
            message: m.message,
            message_id: m.id,
            project_id: m.project_id,
            sent_by: m.user?.full_name,
          })),
        ],
        [],
      ),
    ...opts,
  });
