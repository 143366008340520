import * as R from "ramda";

import { indexByID } from "utils/func";

import resourceOperations from "./resourceOperations";

const operation = resourceOperations({
  basePath: "/api/entities",
  baseKey: ["entities"],
});

export const useEntitiesList = (opts = {}) => operation.useRead(null, opts);
export const useEntitiesListForType = (type, { select = R.identity, ...opts } = {}) =>
  useEntitiesList({
    select: R.pipe(R.filter(R.propEq("type", type)), select),
    ...opts,
  });

export const useFeesList = (opts = {}) => useEntitiesListForType("Fee", opts);
export const useFieldsList = (opts = {}) => useEntitiesListForType("Field", opts);
export const useRootFieldsList = ({ select, ...opts } = {}) =>
  useFieldsList({
    ...opts,
    select: R.compose(select, R.filter(R.propSatisfies(R.isNil, "parent_id"))),
  });
export const useRequirementsList = (opts = {}) => useEntitiesListForType("Requirement", opts);
export const useDepartmentsList = (opts = {}) => useEntitiesListForType("Department", opts);
export const useGuidesList = (opts = {}) => useEntitiesListForType("Guide", opts);

const findList = (idents) => R.compose(R.props(idents), R.indexBy(R.prop("identifier")));
const findRecord = (ident) => R.compose(R.prop(ident), R.indexBy(R.prop("identifier")));
const findRecordByID = (id) => R.compose(R.prop(id), indexByID);

export const useFindEntities = (idents, opts = {}) =>
  useEntitiesList({ ...opts, select: findList(idents) });

export const useEntity = (identifier, opts = {}) =>
  useEntitiesList({ ...opts, select: findRecord(identifier) });

export const useEntityById = (type, id, opts = {}) =>
  useEntitiesListForType(type, { ...opts, select: findRecordByID(id) });
