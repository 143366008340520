import { useMutation, useQuery, useQueryClient } from "react-query";

import invalidations from "queries/invalidations";
import keys from "queries/keys";
import ocClient from "services/ocClient";

export function useRequirementApplication(projectID, id) {
  return useQuery(
    keys.requirementApplications(projectID, id),
    async () => {
      const response = await ocClient.get(`/api/requirement_applications/${id}`);
      return response.data;
    },
    { staleTime: 0 },
  );
}

export function useAdminRequirementApplication(projectID, id) {
  return useQuery(
    keys.admin.requirementApplications(projectID, id),
    async () => {
      const response = await ocClient.get(`/api/admin/requirement_applications/${id}`);
      return response.data;
    },
    { staleTime: 0 },
  );
}

export function useIssuedRequirementAssets(projectID, raID, opts = {}) {
  return useQuery(
    keys.requirementApplicationAssets(projectID, raID),
    async () => {
      const response = await ocClient.get(`/api/issued_requirements/${raID}`);
      return response.data;
    },
    { staleTime: 0, ...opts },
  );
}
// TODO: onError should handle auth, toc, etc
export const useMutateRequirementApplication = (opts) => {
  const queryClient = useQueryClient();

  return useMutation((ra) => ocClient.put(`/api/admin/requirement_applications/${ra.id}`, ra), {
    onSuccess: (app) => {
      // TODO: use queryClient.setQueryData(path(ra.id), data) once return format is same as show
      invalidations.requirementApplications(queryClient, app.project_id, app.id);
    },
    ...opts,
  });
};

export function useSubmitApplication(opts) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) => ocClient.put(`/api/requirement_applications/${id}/submit`),
    onSuccess: (app) => {
      invalidations.requirementApplications(queryClient, app.project_id, app.id);
      invalidations.projects(queryClient, app.project_id);
    },
    ...opts,
  });
}
