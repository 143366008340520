import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { NavLink, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Link, useLocation } from "react-router-dom-v5-compat";

import { Button, SmallButton } from "components/Button";
import Header from "components/Header";
import Icon, { CircularIcon } from "components/Icon";
import Markdown from "components/Markdown";
import { ButtonDiv } from "components/accessibility/Div";
import { Form, FormActions } from "components/forms/Layout";
import Fields from "components/guides/Fields";
import SendMessageBadge from "components/messaging/SendMessageBadge";
import Download from "components/projects/Download";
import { MaybeUnansweredFeeFieldsMessage } from "components/projects/SummaryTable";
import Messages from "components/projects/handoff/Messages";
import RequirementsByDepartment from "components/projects/handoff/RequirementsByDepartment";
import RequirementsByDocuments from "components/projects/handoff/RequirementsByDocuments";
import RequirementsBySection from "components/projects/handoff/RequirementsBySection";
import FlashMessage from "components/shared/FlashMessage";
import InlineModal from "components/shared/InlineModal";
import Text from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { useAuthnState } from "contexts/auth";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { useIsLoginEnabled } from "contexts/featureFlags";
import { useSession } from "contexts/session";
import useToggleState from "hooks/useToggleState";
import { sumFees } from "reducers/fees";
import { selectFieldByKey } from "reducers/fields";
import {
  getHandoffInstructions as getHandoffInstructionsGuide,
  getHandoffSections,
  selectGuideByID,
} from "reducers/guides";
import {
  getCurrentRequirementApplications,
  getLockedFieldIDs,
  isEvent,
  selectRequirementFees,
} from "reducers/projects";
import { getHandoffInstructionsMarkdown as getHandoffInstructionsTenant } from "reducers/tenant";
import { useProjectDocuments } from "selectors/documents";
import standardStyles from "sharedStyles/standard.scss";
import { formatMoney, formatMultiLineAddress } from "utils/format";
import { indexByID, isPresent } from "utils/func";
import routes from "utils/routes";
import { wasLoginSkipped } from "utils/sessionStorage";
import { requirementApplicationPropType } from "utils/sharedPropTypes";

import styles from "./Handoff.scss";
import HandoffSummary, { useIsViewable } from "./handoff/Summary";

const useNameField = () => {
  const { record } = useAnswerContext();
  const nameField = isEvent(record) ? "event_name" : "project_name";
  return useSelector((state) => selectFieldByKey(state, nameField));
};

const HandoffHeader = ({ requirementApplications }) => {
  const { record } = useAnswerContext();
  console.log("record", record);
  const {
    value: projectNameModalIsActive,
    setOn: activateProjectNameModal,
    setOff: deactivateProjectNameModal,
  } = useToggleState(false);

  const onProjectNameSuccess = useCallback(() => {
    deactivateProjectNameModal();
  }, [deactivateProjectNameModal]);

  const onProjectNameClose = useCallback(() => {
    deactivateProjectNameModal();
  }, [deactivateProjectNameModal]);

  const { project_name: projectName } = record.answers;
  const projectAddress = record.business_address;

  const totalFees = useSelector((state) =>
    R.compose(sumFees, R.values, indexByID, selectRequirementFees)(state, record),
  );
  const description = R.path(["answers", "project_description"], record);
  const addressLines = formatMultiLineAddress(projectAddress);
  const applicationCount = R.length(requirementApplications);
  const nameField = useNameField();
  const projectNameEditable = !getLockedFieldIDs(record).includes(nameField?.id);

  return (
    <div className={styles.headContainer}>
      <div className={styles.head}>
        <div className={styles.headContent}>
          <div className={styles.projectName}>
            <h2 data-project-name={projectName}>
              {projectName || <Text t="projects.handoff.unnamed_project" />}
            </h2>
            {projectNameEditable && (
              <ButtonDiv onClick={activateProjectNameModal}>
                <Icon icon="pen-to-square" />
              </ButtonDiv>
            )}
          </div>
          <div className={styles.projectId}>
            <Text t="guides.header.nav.project_id" /> #{record.id}
          </div>
          {projectNameModalIsActive && (
            <EditProjectNameModal onClose={onProjectNameClose} onSuccess={onProjectNameSuccess} />
          )}
          {description && <div className={styles.description}>{description}</div>}
          <div className={styles.headSections}>
            {projectAddress && (
              <div className={styles.headSection}>
                <div className={styles.icon}>
                  <CircularIcon icon="map-marker-alt" size="2x" inverse />
                </div>
                <div>
                  {addressLines[0]}
                  {addressLines.length > 1 && <div>{addressLines[1]}</div>}
                </div>
              </div>
            )}
            {applicationCount > 0 && (
              <div className={styles.headSection}>
                <div className={styles.icon}>
                  <CircularIcon icon="file" size="2x" inverse />
                </div>
                <div>
                  <div className={styles.emphasis}>{applicationCount}</div>
                  <Text
                    t={
                      R.length(requirementApplications) === 1
                        ? "projects.handoff.requirement_required"
                        : "projects.handoff.requirements_required"
                    }
                  />
                </div>
              </div>
            )}
            {totalFees > 0 && (
              <div className={styles.headSection}>
                <div className={styles.icon}>
                  <CircularIcon icon="calculator" size="2x" inverse />
                </div>
                <div>
                  <div className={styles.emphasis}>${formatMoney(totalFees)}</div>
                  <Text t="guides.summary_table.total_cost" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
HandoffHeader.propTypes = {
  requirementApplications: PropTypes.arrayOf(requirementApplicationPropType).isRequired,
};

const EditProjectNameModal = ({ onClose, onSuccess }) => {
  const { record, onSave } = useAnswerContext();
  const heading = isEvent(record)
    ? "projects.handoff.event_name_title"
    : "projects.handoff.project_name_title";
  const nameField = useNameField();
  const onSubmit = () => {
    onSave(nameField);
    onSuccess();
  };

  return (
    <InlineModal onClose={onClose} heading={<Text t={heading} />}>
      <Form onSubmit={onSubmit}>
        <Fields fields={[nameField]} />
        <FormActions>
          <div>
            <Button type="submit">
              <Text t="helpers.titles.save" />
            </Button>
          </div>
        </FormActions>
      </Form>
    </InlineModal>
  );
};

const UnsavedProjectBehavior = ({ saveProgressFlowWasDismissed }) => {
  const { isLoggedIn } = useSession();
  const isLoginEnabled = useIsLoginEnabled();
  const isLoginNeeded = isLoginEnabled && !isLoggedIn;

  const { activateLoginModal, loginModalActive } = useAuthnState();

  const showSaveProgressFlow = useCallback(() => {
    if (isLoginNeeded) {
      activateLoginModal();
    }
  }, [activateLoginModal, isLoginNeeded]);

  useEffect(() => {
    if (saveProgressFlowWasDismissed || wasLoginSkipped()) return;
    showSaveProgressFlow();
  }, [saveProgressFlowWasDismissed, showSaveProgressFlow]);

  if (loginModalActive) return null;

  if (!isLoginNeeded) return null;
  return (
    <div className={styles.flash}>
      <FlashMessage heading={<Text t="projects.handoff.save_progress_banner.heading" />} hideClose>
        <div>
          <Text t="projects.handoff.save_progress_banner.message" />
        </div>
        <SmallButton onClick={showSaveProgressFlow}>
          <Text t="projects.handoff.save_progress_banner.cta" />
        </SmallButton>
      </FlashMessage>
    </div>
  );
};

const TabBar = ({ showRequirements, showMessaging }) => {
  const { record } = useAnswerContext();
  const match = useRouteMatch();
  const { total: documentsCount } = useProjectDocuments(record);
  const showDocuments = documentsCount > 0;
  const showSummary = useIsViewable() || (!showRequirements && showMessaging);

  return (
    <div className={styles.tabsBackground}>
      <div className={styles.tabsContainer}>
        <div className={standardStyles.tabsContainer}>
          {showRequirements && (
            <NavLink
              to={`${match.url}/requirements`}
              activeClassName={standardStyles.selected}
              data-requirements-tab
            >
              <Text t="projects.handoff.tabs.requirements" />
            </NavLink>
          )}
          {showMessaging && (
            <NavLink
              to={`${match.url}/messages`}
              activeClassName={standardStyles.selected}
              data-documents-tab
            >
              <Text t="projects.handoff.tabs.messages" />
            </NavLink>
          )}
          {showDocuments && (
            <NavLink
              to={`${match.url}/documents`}
              activeClassName={standardStyles.selected}
              data-documents-tab
            >
              <Text t="projects.handoff.tabs.documents" />
            </NavLink>
          )}
          {showSummary && (
            <NavLink
              to={`${match.url}/summary`}
              activeClassName={standardStyles.selected}
              data-summary-tab
            >
              <Text t="projects.handoff.tabs.summary" />
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
};

const DownloadLink = () => (
  <div className={styles.download}>
    <Download />
  </div>
);

const Handoff = ({ saveProgressFlowWasDismissed }) => {
  const { record: project } = useAnswerContext();
  const tenant = useCurrentTenantInfo();
  const guide = useSelector((state) => selectGuideByID(state, project.guide_id));
  const match = useRouteMatch();
  const { state: locationState } = useLocation();

  const flashMessage = locationState?.flash;

  const requirementApplications = getCurrentRequirementApplications(project);
  const introductionMarkdown =
    getHandoffInstructionsGuide(guide) || getHandoffInstructionsTenant(tenant);
  const sections = getHandoffSections(guide);
  const showSections = !R.isEmpty(sections);
  const showRequirements = isPresent(requirementApplications);
  const showMessaging = tenant.messaging_feature !== "disabled";

  return (
    <div className={styles.pageContainer}>
      <div className={styles.page}>
        <Header hideTitle />
        <HandoffHeader requirementApplications={requirementApplications} />
        <TabBar showRequirements={showRequirements} showMessaging={showMessaging} />

        <main role="main" className={styles.container}>
          {flashMessage && (
            <div className={styles.flash}>
              <FlashMessage heading="Project created">
                This project has been created and a notification was emailed to {project.user.email}
                .&nbsp;
                <Link
                  to={routes.admin.project(project.id)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View project in admin
                </Link>
              </FlashMessage>
            </div>
          )}
          <UnsavedProjectBehavior saveProgressFlowWasDismissed={saveProgressFlowWasDismissed} />

          <div className={styles.contentContainer}>
            <div className={styles.viewBar}>
              {showSections && (
                <Switch>
                  <Route path={`${match.url}/requirements`}>
                    <div className={styles.viewBy}>
                      <div className={styles.viewByLabel}>
                        <Text t="projects.handoff.tabs.view_by" />
                      </div>
                      <NavLink
                        to={`${match.url}/requirements/submission`}
                        activeClassName={styles.selectedView}
                      >
                        <Text t="projects.handoff.tabs.submission" />
                      </NavLink>
                      <NavLink
                        to={`${match.url}/requirements/department`}
                        activeClassName={styles.selectedView}
                        data-department-tab
                      >
                        <Text t="projects.handoff.tabs.department" />
                      </NavLink>
                    </div>
                  </Route>
                </Switch>
              )}
              <Switch>
                <Route path={`${match.url}/requirements`}>
                  <DownloadLink />
                </Route>
                <Route path={`${match.url}/summary`}>
                  <DownloadLink />
                </Route>
              </Switch>
            </div>

            <Switch>
              <Route path={`${match.url}/summary`}>
                <HandoffSummary />
              </Route>
              <Route path={`${match.url}/documents`}>
                <RequirementsByDocuments />
              </Route>
              <Route path={`${match.url}/messages`}>
                <Messages />
              </Route>
              {showRequirements && (
                <Route path={`${match.url}/requirements`}>
                  <div className={styles.introductionContainer}>
                    <Markdown source={introductionMarkdown} />
                  </div>

                  <Switch>
                    <Route path={`${match.url}/requirements/submission`}>
                      <RequirementsBySection />
                    </Route>
                    <Route path={`${match.url}/requirements/department`}>
                      <RequirementsByDepartment />
                    </Route>
                    <Route>
                      <Redirect
                        to={
                          showSections
                            ? `${match.url}/requirements/submission`
                            : `${match.url}/requirements/department`
                        }
                      />
                    </Route>
                  </Switch>
                </Route>
              )}

              <Route>
                <Redirect
                  to={showRequirements ? `${match.url}/requirements` : `${match.url}/summary`}
                />
              </Route>
            </Switch>
          </div>

          <div className={styles.unansweredMessage}>
            <Switch>
              <Route path={`${match.url}/requirements`}>
                <MaybeUnansweredFeeFieldsMessage />
              </Route>
            </Switch>
          </div>
        </main>
      </div>
      <div className={styles.fixedFooter}>
        <Switch>
          <Route path={`${match.url}/messages`} />
          <Route>
            <div className={styles.sendMessageBadge}>
              <SendMessageBadge />
            </div>
          </Route>
        </Switch>
        <div id="fixed-footer" />
      </div>
    </div>
  );
};

export default Handoff;
