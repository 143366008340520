import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import CurrencyField from "components/project_form/CurrencyField";
import { useAnswerContext } from "containers/withAnswerContext";
import { getAnswerForField } from "reducers/answerContexts";
import { getFeeCost, selectFeesForRequirementID } from "reducers/fees";
import { selectSystemFieldBySlug } from "reducers/fields";
import { isPresent } from "utils/func";

import styles from "./shared.scss";

const FeeOverride = ({ record, fee, onChange, value }) => {
  const field = {
    key: fee.identifier,
  };
  const placeholder = value ? undefined : getFeeCost(record, fee) || 0;

  return (
    <div data-fee-override={fee.identifier} className={styles.feeContainer}>
      <h4>{fee.name}</h4>
      <CurrencyField
        field={field}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        onSave={R.T}
      />
    </div>
  );
};

const EditFeesOverridesField = ({ requirement }) => {
  const { record: changeset, onChange, onSave } = useAnswerContext();
  const fees = useSelector((state) => selectFeesForRequirementID(state, requirement.id));

  const field = useSelector((s) => selectSystemFieldBySlug(s, "overrides"));
  const value = getAnswerForField(changeset, field);
  const onChangeField = R.curry(onChange)(field);

  const updateValueFor = R.curry((identifier, overrideValue) => {
    if (isPresent(overrideValue)) {
      // override with the float value of overrideValue: https://github.com/opencounter/opencounter/issues/4197
      onChangeField(R.assoc(identifier, Number(overrideValue.replace(/[^0-9.]/g, "")), value));
      onSave(field);
    } else {
      onChangeField(R.dissoc(identifier, value));
      onSave(field);
    }
  });

  return (
    <div>
      {fees.map((fee) => (
        <FeeOverride
          key={fee.id}
          fee={fee}
          onChange={updateValueFor(fee.identifier)}
          record={changeset}
          value={R.prop(fee.identifier, value)}
        />
      ))}
    </div>
  );
};

export default EditFeesOverridesField;
