import React, { useCallback, useContext, useMemo, useReducer, useState } from "react";
import * as R from "ramda";

import useComponent from "hooks/useComponent";

const HotKeyContext = React.createContext();

const hotKeysReducer = (state, action) => {
  switch (action.type) {
    case "add":
      return [...state, action.hotkey];
    case "remove":
      return R.without([action.hotkey], state);
    default:
      throw new Error();
  }
};

const HotKeysProvider = ({ children }) => {
  const [enabled, setEnabled] = useState(true);
  const [hotkeys, dispatch] = useReducer(hotKeysReducer, []);

  const [Overlay, setOverlay, clearOverlay] = useComponent();
  const [Container, setContainer, clearContainer] = useComponent();
  const close = useCallback(() => {
    clearOverlay();
    clearContainer();
    setEnabled(true);
  }, [clearContainer, clearOverlay]);

  const register = useCallback((hotkey) => dispatch({ type: "add", hotkey }), [dispatch]);
  const unregister = useCallback((hotkey) => dispatch({ type: "remove", hotkey }), [dispatch]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setOverlayAndDisable = useCallback((overlay) => {
    setOverlay(overlay);
    setEnabled(false);
  });

  const value = useMemo(
    () => ({
      setOverlay: setOverlayAndDisable,
      setContainer,
      register,
      unregister,
      enabled,
      setEnabled,
      close,
    }),
    [setOverlayAndDisable, setContainer, register, unregister, enabled, setEnabled, close],
  );

  return (
    <HotKeyContext.Provider value={value}>
      <Container close={close}>
        <Overlay close={close} hotkeys={hotkeys} />
        {children}
      </Container>
    </HotKeyContext.Provider>
  );
};

export default HotKeysProvider;
export const useHotKeyContext = () => useContext(HotKeyContext);
