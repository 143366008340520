import * as R from "ramda";

import resourceOperations from "../resourceOperations";

const operation = resourceOperations({
  basePath: "/api/config/versions",
  baseKey: ["config", "versions"],
  dataKey: "version",
  versioned: true, // FIXME(rtlong): this should not be versioned, but currently setting this to false results in changed behavior in the Version diff
});

// TODO: should have a way to not only invalidate via pusher, but force other config users to update when HEAD changes
export const useVersion = (id, opts = {}) => operation.useRead(id, opts);
export const useVersions = (opts = {}) =>
  operation.useRead(null, opts, R.pipe(R.values, R.reverse));
export const useRecordHistory = ({ id, type }, opts = {}) => {
  const queryParams = new URLSearchParams({ id, type }).toString();
  return operation.useRead(`history?${queryParams}`, opts);
};

export const useUpdateVersion = (id, opts = {}) => operation.useUpdate(id, opts);
export const useCommitVersion = (id, opts = {}) => operation.useUpdate([id, "commit"], opts);
