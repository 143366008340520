import { useQuery } from "react-query";

import keys from "queries/keys";
import ocClient from "services/ocClient";

import resourceOperations from "./resourceOperations";

const operation = resourceOperations({
  basePath: "/api/geoms",
  baseKey: "geoms",
  dataKey: "geom",
});

// TODO: useQueries here instead?
export const useGeoms = (ids = null) =>
  useQuery(
    keys.geoms(ids),
    async () => {
      if (ids && ids.length === 0) return Promise.resolve([]);
      const response = await ocClient.get("/api/geoms", ids === null ? {} : { params: { ids } });
      return response.data;
    },
    { staleTime: Infinity },
  );
export const useGeomsList = (opts = {}) => operation.useRead("", opts);
