import React from "react";
import classnames from "classnames";

import Icon from "components/Icon";
import Tooltip from "components/Tooltip";
import ErrorMessage from "components/project_form/ErrorMessage";
import { isPresent } from "utils/func";

import styles from "./Layout.scss";

export const LabeledInput = ({
  label,
  labelIcon,
  fieldName,
  error,
  children,
  fieldType,
  helpText,
  AdditionalHelp,
  width = "auto",
  ignoreLabelClick = false,
  classNames = [],
}) => (
  <div
    className={classnames(styles.labeledContainer, classNames)}
    style={{ width }}
    data-field-key={fieldName}
    data-field-component={fieldType}
  >
    <label aria-invalid={isPresent(error)} {...(ignoreLabelClick && { htmlFor: "" })}>
      <div className={styles.label}>
        {labelIcon && <Icon icon={labelIcon} />}
        <span>{label}</span>
        <Help helpText={helpText} AdditionalHelp={AdditionalHelp} />
      </div>
      <div className={styles.field}>{children}</div>
      {error && <ErrorMessage className={styles.error} error={error} />}
    </label>
  </div>
);

export const ApplicantLabeledInput = (props) => (
  <LabeledInput {...props} classNames={[styles.applicantContainer]} />
);

const Help = ({ helpText, AdditionalHelp }) => (
  <>
    {helpText && (
      <span className={styles.help}>
        <Tooltip icon="info-circle" message={helpText} />
      </span>
    )}
    {AdditionalHelp && <AdditionalHelp />}
  </>
);

export const CompactLabeledInput = (props) => (
  <LabeledInput {...props} classNames={[styles.compactContainer]} />
);

/**
 * A horiszontal form label
 *
 * @param {*} props - pass through props to the labeled input, classNames will be overriden
 * @property {boolean}  props.reversed - the label will appear after the input (for checkboxes, radio buttons, etc)
 * @returns
 */
export const HorizontalLabeledInput = ({ reversed, ...props }) => (
  <LabeledInput
    {...props}
    classNames={classnames(styles.horizontalContainer, {
      [styles.horizontalContainerReversed]: reversed,
    })}
  />
);

export const UnlabeledInputWithHelp = ({ helpText, AdditionalHelp, children }) => (
  <div>
    {children}
    <Help helpText={helpText} AdditionalHelp={AdditionalHelp} />
  </div>
);

export const UnlabeledInputWithErrors = ({ error, children }) => (
  <div className={styles.unlabledContainer}>
    {children}
    {error && <Icon icon="exclamation" title={error.message} />}
  </div>
);

export const Form = ({ children, className, ...props }) => (
  <form {...props} className={classnames(className, styles.form)}>
    {children}
  </form>
);
export const FormActions = ({ children }) => <div className={styles.formActions}>{children}</div>;

export const FormRow = ({ children }) => <div className={styles.formRow}>{children}</div>;
export const CompactFormRow = ({ children }) => (
  <div className={styles.compactFormRow}>{children}</div>
);
export const ListFormRow = ({ children, deleting = false, forwardedRef, ...props }) => (
  <div
    className={classnames(styles.listFormRow, { [styles.deleting]: deleting })}
    ref={forwardedRef}
    {...props}
  >
    {children}
  </div>
);

export const ListFormRowHandle = (props) => (
  <div className={styles.dragHandle} title="Drag to sort" {...props}>
    <Icon icon="bars" />
  </div>
);
export const ListFormCell = ({ children, ...props }) => (
  <div className={styles.listFormCell} {...props}>
    {children}
  </div>
);
export const SubtypeList = ({ children }) => <div className={styles.subtypeList}>{children}</div>;
