import React from "react";
import classnames from "classnames";
import h from "hastscript";
import PropTypes from "prop-types";
import * as R from "ramda";
import ReactMarkdown from "react-markdown";
import remarkDirective from "remark-directive";
import visit from "unist-util-visit";

import ExternalLink from "components/ExternalLink";
import { isAbsolute, isMailTo } from "utils/urls";

import styles from "./Markdown.scss";

const shouldOpenNewTab = R.startsWith("/api/accela/redirect");
const LinkRenderer = (projectID = null, requirementApplicationID = null) => {
  const WithLinks = ({ href, children, node: _node, ...props }) => {
    if (projectID !== null) href = href.replace(/\/\$projectID/g, `/${projectID}`);
    if (requirementApplicationID !== null)
      href = href.replace(/\/\$requirementApplicationID/g, `/${requirementApplicationID}`);

    if (isMailTo(href))
      return (
        <a href={href} {...props}>
          {children}
        </a>
      );

    if (isAbsolute(href) || shouldOpenNewTab(href))
      return (
        <ExternalLink href={href} {...props}>
          {children}
        </ExternalLink>
      );

    return (
      <a href={href} {...props}>
        {children}
      </a>
    );
  };
  return WithLinks;
};

const Heading = (level) => {
  const H = ({ _node, ...props }) => React.createElement(`h${level}`, props);
  H.displayName = `H${level}`;
  return H;
};

const directiveButton = () => (tree) => {
  visit(tree, (node) => {
    if (node.name !== "button") return;

    // support :button[text]{href="url"} syntax
    node.name = "a";
    node.attributes.class = styles.button;
    const data = node.data || (node.data = {});
    const hast = h(node.name, node.attributes);

    data.hName = node.name;
    data.hProperties = hast.properties;
  });
};

export const STEPS_CONTEXT = "steps-context";

const Markdown = ({
  source,
  className,
  inline = false,
  maxHeadingLevel = null,
  context = null,
  projectID, // TODO: it would nice for these ids to come from the providers or urls but we don't have that setup yet
  requirementApplicationID,
}) => {
  if (!source) return null;
  const components = { a: LinkRenderer(projectID, requirementApplicationID) };
  if (maxHeadingLevel != null) {
    components.h1 = Heading(maxHeadingLevel);
    components.h2 = Heading(Math.min(6, maxHeadingLevel - 1));
    components.h3 = Heading(Math.min(6, maxHeadingLevel - 2));
    components.h4 = Heading(Math.min(6, maxHeadingLevel - 3));
    components.h5 = Heading(Math.min(6, maxHeadingLevel - 4));
  }
  return (
    <ReactMarkdown
      className={classnames(styles.markdown, className, {
        [styles.inline]: inline,
        [styles[context]]: !!context,
      })}
      children={source}
      components={components}
      remarkPlugins={[remarkDirective, directiveButton]}
    />
  );
};
Markdown.propTypes = {
  source: PropTypes.string,
  inline: PropTypes.bool,
};

export default Markdown;
