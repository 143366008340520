import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Icon from "components/Icon";
import { ButtonDiv } from "components/accessibility/Div";
import useToggleState from "hooks/useToggleState";

import styles from "./ApplicantFormSection.scss";

// TODO: unify with projects/requirement/Section
const ApplicantFormSection = ({ name, className, children, initiallyActive = true }) => {
  const { value: active, toggle: toggleActive } = useToggleState(initiallyActive);

  return (
    <div
      className={classnames(styles.section, className, {
        [styles.active]: active,
      })}
      data-section={name}
      data-open={active}
    >
      <ButtonDiv className={styles.sectionHeader} onClick={toggleActive}>
        <div className={styles.sectionInfo}>
          <div className={styles.sectionTitle}>{name}</div>
        </div>

        <div className={styles.openItem}>
          <Icon icon={`angle-${active ? "up" : "down"}`} size="lg" />
        </div>
      </ButtonDiv>

      <div className={styles.sectionContent}>{children}</div>
    </div>
  );
};
ApplicantFormSection.propTypes = {
  name: PropTypes.string.isRequired,
  initiallyActive: PropTypes.bool,
};

export default ApplicantFormSection;
