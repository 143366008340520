import React, { createContext, useEffect, useMemo, useReducer } from "react";
import PropTypes from "prop-types";
import Pusher from "pusher-js";

import { isProductionBuild } from "env";
import { useSession } from "contexts/session";
import ocClient from "services/ocClient";
import { isPresent } from "utils/func";
import { childrenPropType } from "utils/sharedPropTypes";

const authUser = ({ socketId }) => ocClient.post("/pusher/auth_user", { socket_id: socketId });
const authChannel = ({ socketId: socket_id, channelName: channel_name }) =>
  ocClient.post(`/pusher/auth?channel=${channel_name}`, { socket_id, channel_name });

const createPusherClient = ({ key, cluster, encrypted, forceTLS }) => {
  console.debug("PSHR new Pusher()");
  const pusher = new Pusher(key, {
    encrypted,
    forceTLS,
    cluster,
    userAuthentication: {
      async customHandler({ socketId }, callback) {
        try {
          const { data } = await authUser({ socketId });
          callback(null, data);
        } catch (e) {
          callback(e, null);
        }
      },
    },
    channelAuthorization: {
      async customHandler({ socketId, channelName }, callback) {
        try {
          const { data } = await authChannel({ socketId, channelName });
          callback(null, data);
        } catch (e) {
          console.error(
            "PSHR createPusherClient.channelAuthorization.error:",
            socketId,
            channelName,
            e,
          );
          callback(e, null);
        }
      },
    },
  });

  window.pusher = pusher;
  pusher.signin();

  const teardown = (reason) => {
    console.debug("PSHR TEARDOWN", { reason });
    pusher.disconnect();
    pusher.connection.unbind_all();
    pusher.unbind_all();
  };
  if (!isProductionBuild) {
    pusher.connection.bind_global((event_name, data) => {
      console.debug("PSHR connection.global", event_name, data);
    });
    // pusher.connection.bind("message", ({ event, channel, data }) =>
    //   console.debug("PSHR connection.message", { event, channel }, data),
    // );
    pusher.bind_global((event_name, data) => {
      console.debug("PSHR global", event_name, data);
    });
  }
  return { pusher, teardown };
};

export const PusherContext = createContext();
PusherContext.displayName = "PusherContext";

export const PusherProvider = ({ config, children }) => {
  const {
    user: { id: userID },
    isLoaded: sessionLoaded,
  } = useSession();

  const [{ pusher, state, teardown }, dispatch] = useReducer(
    (state, [action, payload]) => {
      switch (action) {
        case "connected":
        case "disconnected":
          return { state: action, pusher: payload.pusher, teardown: payload.teardown };
        case "error":
          return { state: "error", pusher: null, error: payload };
        case "retry":
          return { state: "retrying", pusher: null, teardown: null };
        default:
          throw new Error(`Unspecified action ${action}`);
      }
    },
    { state: "initial" },
  );

  // we don't have any need for Pusher until we have a user ID (even a guest User)
  const enabled = sessionLoaded && isPresent(config) && isPresent(userID) && state !== "error";

  useEffect(() => {
    if (!enabled) return undefined;
    if (pusher)
      return () => {
        console.debug("PusherProvider > useEffect > unrender", { enabled, config, pusher });
        teardown("PusherProvider > useEffect > unrender");
      };

    const result = createPusherClient(config);
    const {
      pusher: { connection },
    } = result;

    // cleanup subscriptions when the query goes out of scope
    const onError = (error) => {
      connection.unbind("error", onError);
      result.teardown("Pusher.connection > onError");
      dispatch(["error", error]);
      console.error("PSHR error", error);
      setTimeout(() => {
        dispatch("retry");
      }, 20000);
    };
    connection.bind("error", onError);

    const onConnected = () => {
      connection.unbind("connected", onConnected);
      dispatch(["connected", result]);
    };
    connection.bind("connected", onConnected);

    const onDisconnected = () => {
      connection.unbind("disconnected", onDisconnected);
      dispatch(["disconnected", result]);
    };
    connection.bind("disconnected", onDisconnected);

    return undefined;
  }, [enabled, config, pusher, teardown]);

  const connected = state === "connected";
  const ctxValue = useMemo(() => ({ pusher, connected }), [pusher, connected]);
  return <PusherContext.Provider value={ctxValue}>{children}</PusherContext.Provider>;
};
PusherProvider.propTypes = {
  children: childrenPropType,
  config: PropTypes.object.isRequired,
};
