import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import Icon, { CircularIcon } from "components/Icon";
import { ButtonDiv } from "components/accessibility/Div";
import Fields from "components/guides/Fields";
import { LayoutWithLock } from "components/project_form/Field";
import CircularProgressBar from "components/utilities/CircularProgressBar";
import { withMappedAnswerContext } from "containers/withAnswerContext";
import useToggleState from "hooks/useToggleState";
import { getUnansweredCountForFields } from "reducers/projects";

import styles from "./Section.scss";

const calculateProgress = (record, section) => {
  const requiredFields = R.filter((f) => f.required, section.fields);
  const answered = requiredFields.length - getUnansweredCountForFields(record, requiredFields);

  if (R.isEmpty(requiredFields)) return 100;

  return Math.abs(Math.round((answered / requiredFields.length) * 100));
};

const Progress = ({ record, section }) => {
  const percentage = calculateProgress(record, section);

  if (percentage === 100) {
    return <CircularIcon icon="check" bgColor="#5cb85c" iconColor="#fff" size="md" />;
  }
  return <CircularProgressBar percentage={percentage} />;
};

const Section = ({
  section,
  answerContext: { record },
  progressIcon = true,
  content = null,
  initiallyActive = true,
  fieldProps = {},
  className,
}) => {
  const { value: active, toggle: toggleActive } = useToggleState(initiallyActive);

  return (
    <div
      className={classnames(styles.section, className, {
        [styles.active]: active,
        [styles.withIcon]: progressIcon,
      })}
      data-section={section.name}
      data-open={active}
    >
      <ButtonDiv className={styles.sectionHeader} onClick={toggleActive}>
        <div className={styles.sectionInfo}>
          {progressIcon && (
            <div className={styles.sectionProgress}>
              <Progress section={section} record={record} />
            </div>
          )}
          <div className={styles.sectionTitle}>{section.name}</div>
        </div>
        <div className={styles.openItem}>
          <Icon icon={`angle-${active ? "up" : "down"}`} size="lg" />
        </div>
      </ButtonDiv>
      <div className={styles.sectionContent}>
        {content}
        <Fields
          {...fieldProps}
          fields={section.fields}
          behaviorType="inline"
          layout={LayoutWithLock}
        />
      </div>
    </div>
  );
};
Section.propTypes = {
  section: PropTypes.object.isRequired,
  isReview: PropTypes.bool,
  fieldProps: PropTypes.object,
};
const withSectionContext = withMappedAnswerContext(({ section }, { context }) =>
  R.assoc("requirement", context, section),
);
const SectionWithContext = withSectionContext(Section);
export default SectionWithContext;
