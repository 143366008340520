import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useQueryClient } from "react-query";

import { useCurrentTenantInfo } from "contexts/currentTenant";
import ocClient from "services/ocClient";
import { X_TENANT_VERSION } from "utils/httpHeaderNames";

const VersionedClientContext = React.createContext();

const VersionedClientProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const { version_number: versionNumber } = useCurrentTenantInfo();

  const client = useMemo(
    () =>
      ocClient.create({
        headers: {
          [X_TENANT_VERSION]: versionNumber,
        },
      }),
    [versionNumber],
  );

  console.debug("VersionedClientProvider.versionNumber =", versionNumber);
  useEffect(() => {
    queryClient.invalidateQueries({
      predicate: (query) => {
        const p = query?.meta?.versionedEndpoint;
        if (p) console.debug("VersionedClientProvider.invalidateQueries", query?.queryKey);
        return p;
      },
    });
  }, [versionNumber, queryClient]);
  return (
    <VersionedClientContext.Provider value={client}>{children}</VersionedClientContext.Provider>
  );
};

export default VersionedClientProvider;

export const useVersionedClient = () => useContext(VersionedClientContext);
export const useVersionedClientRef = () => {
  const clientRef = useRef();
  const client = useContext(VersionedClientContext);

  useEffect(() => {
    clientRef.current = client;
  }, [client]);

  return clientRef;
};
