import React from "react";

import Text from "containers/Text";
import { isPayable } from "reducers/requirementApplications";
import {
  getDownloadURL,
  isAccelaCitizenAccess,
  isExternalLinkPerDepartment,
  isFreeform,
  isInPerson,
  isIntegrated,
  isMailIn,
} from "reducers/requirements";
import { isBlank } from "utils/func";
import {
  departmentPropType,
  requirementApplicationPropType,
  requirementPropType,
} from "utils/sharedPropTypes";

import styles from "./StartButton.scss";

const StartButton = ({ requirement, requirementApplication, department }) => {
  const startText =
    requirement.type === "Upload" ? (
      <Text t="projects.handoff.upload" />
    ) : (
      <Text t="projects.handoff.apply_online" />
    );

  if (isIntegrated(requirement)) {
    return (
      <div data-requirement-start className={styles.btn}>
        {isPayable(requirementApplication) ? <Text t="projects.handoff.pay_now" /> : startText}
      </div>
    );
  }

  if (isFreeform(requirement))
    return (
      <div className={styles.btn}>
        <Text t="projects.handoff.learn_more" />
      </div>
    );

  if (isExternalLinkPerDepartment(requirement))
    return (
      <div className={styles.btn}>
        <Text t="projects.handoff.apply_online" />
      </div>
    );

  if (isAccelaCitizenAccess(requirement))
    return (
      <div className={styles.btn}>
        <Text t="projects.handoff.apply_via_aca" />
      </div>
    );

  if (isMailIn(requirement) || isInPerson(requirement)) {
    const url = getDownloadURL(requirement, department);
    if (isBlank(url))
      return (
        <div className={styles.btn}>
          <Text t="projects.handoff.apply_in_person" />
        </div>
      );
    return (
      <div className={styles.btn}>
        <Text t="download" />
      </div>
    );
  }

  return null;
};
StartButton.propTypes = {
  department: departmentPropType.isRequired,
  requirement: requirementPropType.isRequired,
  requirementApplication: requirementApplicationPropType.isRequired,
};

export default StartButton;
