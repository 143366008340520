import React, { useEffect } from "react";
import * as R from "ramda";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom-v5-compat";

import { AdminButton } from "components/Button";
import { FlexColumn } from "components/FlexColumn";
import Header from "components/Header";
import EditFeesOverridesField from "components/admin/changesets/EditFeesOverridesField";
import EditRequirementsOverridesField from "components/admin/changesets/EditRequirementsOverridesField";
import ApplicantFormSection from "components/forms/ApplicantFormSection";
import {
  ApplicantField,
  RadioButtonInput,
  TextAreaInput,
  TextInput,
} from "components/forms/HookFields";
import { Form } from "components/forms/Layout";
import { SuperAdminFeature } from "components/privilegedUserFeatures";
import SummaryTable from "components/projects/SummaryTable";
import Field from "containers/Field";
import { useAnswerContext } from "containers/withAnswerContext";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { useCreateEphemeralProject, useEphemeralProject } from "features/ephemeral/queries";
import withEphemeralProvider from "features/ephemeral/withEphemeralProvider";
import useDefferedButtonHandler from "hooks/useDefferedButtonHandler";
import { useGuidesList, useRequirementsList } from "queries/entities";
import { selectFeesForRequirementID } from "reducers/fees";
import { selectSystemFieldBySlug } from "reducers/fields";
import { isValidEmail } from "utils/fieldValidators";
import { defineFields } from "utils/formDefinitions";
import routes from "utils/routes";
import { listToHumanizedOptions } from "utils/toOptions";

import FooterShell from "../FooterShell";
import styles from "./index.scss";

const applicantFields = defineFields({
  first_name: {
    name: "applicant[first_name]",
    label: "First Name *",
    Component: TextInput,
    validate: { required: "First name is required" },
  },
  last_name: {
    name: "applicant[last_name]",
    label: "Last Name *",
    Component: TextInput,
    validate: { required: "Last name is required" },
  },
  email: {
    name: "applicant[email]",
    label: "Email *",
    Component: TextInput,
    validate: {
      required: "Email is required",
      validate: (e) => isValidEmail(e) || "Invalid email",
    },
  },
  note: {
    name: "note",
    label: "Add a note to the applicant (optional)",
    Component: TextAreaInput,
  },
});

const allProjectTypes = ["business", "residential"];

const ProjectType = (props) => {
  const tenant = useCurrentTenantInfo();
  const projectTypes = R.intersection(allProjectTypes, tenant.guide_types);
  const projectTypeOptions = listToHumanizedOptions(projectTypes);
  return <RadioButtonInput {...props} options={projectTypeOptions} />;
};

const projectFields = defineFields({
  project_type: {
    Component: ProjectType,
    validate: { required: "Project type is required" },
  },
});

const ProjectField = ({ slug }) => {
  const field = useSelector((s) => selectSystemFieldBySlug(s, slug));
  if (!field) return null;

  return <Field field={field} required={false} fullWidth />;
};

const useActiveRequirements = () => {
  const { record: project } = useAnswerContext();
  const requirementIDs = project.requirement_ids || [];
  const { data: requirements = [] } = useRequirementsList({
    select: R.filter(R.propSatisfies((id) => requirementIDs.includes(id), "id")),
    enabled: requirementIDs.length > 0,
  });
  return requirements;
};

const useAvailableFees = () => {
  const requirements = useActiveRequirements();
  return useSelector((state) =>
    R.flatten(requirements.map((requirement) => selectFeesForRequirementID(state, requirement.id))),
  );
};

const BuildAProjectComponent = () => {
  const { record: project, onSave } = useAnswerContext();
  const projectType = useWatch({ name: "project_type" });

  const { data: guide } = useGuidesList({
    select: R.find(R.propEq("guide_type", projectType)),
  });

  const requirementIDs = guide ? guide.requirement_ids : [];
  const { data: requirements = [] } = useRequirementsList({
    select: R.filter(R.propSatisfies((id) => requirementIDs.includes(id), "id")),
    enabled: requirementIDs.length > 0,
  });
  const { setValue } = useFormContext();

  const existingUser = project?.user?.email;
  const email = useWatch({ name: "applicant[email]" });

  useEffect(() => {
    if (isValidEmail(email)) {
      onSave({ key: "overrides" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (existingUser) {
      const fullName = project.user.full_name;
      const [firstName, lastName] = fullName.split(" ", 2);
      setValue("applicant[first_name]", firstName);
      setValue("applicant[last_name]", lastName);
    } else {
      setValue("applicant[first_name]", "");
      setValue("applicant[last_name]", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingUser]);

  const fees = useAvailableFees();

  return (
    <Layout>
      <Form>
        <ApplicantFormSection name="Project Information">
          <ProjectField slug="project-name" />
          <ProjectField slug="project-description" />
          <ApplicantField definition={projectFields.project_type} />
          <ProjectField slug="address" />
        </ApplicantFormSection>
        <ApplicantFormSection name="Applicant Information">
          <ApplicantField definition={applicantFields.email} />
          <ApplicantField definition={applicantFields.first_name} disabled={existingUser} />
          <ApplicantField definition={applicantFields.last_name} disabled={existingUser} />
          <ApplicantField definition={applicantFields.note} />
        </ApplicantFormSection>

        <ApplicantFormSection name="Requirements">
          <EditRequirementsOverridesField requirements={requirements} />
        </ApplicantFormSection>

        {fees.length > 0 && (
          <ApplicantFormSection name="Fees" initiallyActive={false} className={styles.feesSection}>
            <EditRequirementsFees />
          </ApplicantFormSection>
        )}
      </Form>
    </Layout>
  );
};

const EditRequirementsFees = () => {
  const requirements = useActiveRequirements();

  return (
    <div className={styles.fees}>
      {requirements.map((requirement) => (
        <div key={requirement.id} className={styles.requirementFees}>
          <h3>{requirement.name}</h3>
          <EditFeesOverridesField key={requirement.id} requirement={requirement} />
        </div>
      ))}
    </div>
  );
};

const BuildAProject = withEphemeralProvider(BuildAProjectComponent);

const BuildAProjectContainer = () => {
  const history = useHistory();
  const { state: locationState } = useLocation();
  let projectType = "business";
  if (locationState && locationState.projectType) {
    projectType = locationState.projectType;
  }

  const createMutation = useCreateEphemeralProject({
    onSuccess: (project) => {
      history.push(routes.projectHandoff(project.id), { flash: "project_created" });
    },
  });

  const ephemeralMutation = useEphemeralProject();

  return (
    <SuperAdminFeature>
      <BuildAProject
        createMutation={createMutation}
        ephemeralMutation={ephemeralMutation}
        defaultValues={{ project_type: projectType }}
      />
    </SuperAdminFeature>
  );
};

const Footer = () => {
  const { onCreate, pending } = useAnswerContext();
  const [bufferedOnCreate, bufferedPending] = useDefferedButtonHandler(onCreate, pending);
  return (
    <FooterShell>
      <AdminButton
        onClick={bufferedOnCreate}
        label="Create Project"
        isLoading={bufferedPending}
        data-save-button
      />
    </FooterShell>
  );
};

const Layout = ({ children }) => (
  <FlexColumn fullHeight>
    <FlexColumn exactHeightContent className={styles.container}>
      <Header />
      <FlexColumn tagName="main" role="main" className={styles.withSideGutters}>
        <div className={styles.header}>
          <h1>Create a new project</h1>
          <p>Please complete the form below to create a new project.</p>
        </div>
        <div className={styles.container}>
          <div className={styles.main}>{children}</div>
          <div className={styles.sidebar}>
            <SummaryTable
              headerText="Project requirements"
              showFees={false}
              showAccuracy={false}
              sticky
            />
          </div>
        </div>
      </FlexColumn>
    </FlexColumn>
    <Footer />
  </FlexColumn>
);

export default BuildAProjectContainer;
