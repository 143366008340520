import React from "react";
import * as R from "ramda";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom-v5-compat";

import Modal from "components/layouts/Modal";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import HotKeysProvider from "contexts/hotKeys";
import useRegisteredHotKey from "hooks/useRegisteredHotKey";

import styles from "./Hotkeys.scss";
import { SuperuserFeature } from "./privilegedUserFeatures";

const Shortcut = ({ name, shortcuts }) => (
  <li>
    <div className={styles.shortcut}>{name}</div>
    <div className={styles.keys}>
      {shortcuts.map((shortcut) => (
        <div key={shortcut} className={styles.key}>
          {shortcut}
        </div>
      ))}
    </div>
  </li>
);

const Help = ({ hotkeys }) => {
  const bySection = R.groupBy(R.prop("section"), hotkeys);
  const classNames = {
    contentContainer: styles.helpBoxContainer,
    content: styles.helpBox,
  };

  return (
    <Modal classNames={classNames} hideClose>
      <div className={styles.header}>Shortcuts</div>
      {R.keys(bySection).map((section) => (
        <div className={styles.section} key={section}>
          <div className={styles.sectionHeader}>{section} shortcuts</div>
          <ul>
            {bySection[section].map((keyConfig) => (
              <Shortcut
                key={keyConfig.key}
                name={keyConfig.description}
                shortcuts={(keyConfig.helpKey || keyConfig.key).split("+")}
              />
            ))}
          </ul>
        </div>
      ))}
    </Modal>
  );
};

const useHotkeyNav = (key, location, close) => {
  const navigate = useNavigate();
  useHotkeys(
    key,
    () => {
      navigate(location);
      close();
    },
    [navigate],
  );
};

const GotToLabel = ({ keyName, name }) => (
  <li>
    (<span className={styles.key}>{keyName}</span>){name}
  </li>
);

const GoTo = ({ close }) => {
  const tenant = useCurrentTenantInfo();

  useHotkeyNav("v", "/config/versions", close);
  useHotkeyNav("t", `/config/tenants/${tenant.slug}/edit`, close);
  useHotkeyNav("r", "/config/requirements", close);
  useHotkeyNav("f", "/config/fields", close);
  useHotkeyNav("shift+4", "/config/fees", close);
  useHotkeyNav("a", "/admin/applications", close);
  useHotkeyNav("p", "/admin/projects", close);

  useHotkeys("s", () => {
    const tab = window.open("/?version=HEAD", "_blank");
    close();
    tab.focus();
  });

  const classNames = {
    contentContainer: styles.helpBoxContainer,
    content: styles.goto,
  };

  return (
    <Modal classNames={classNames} hideClose>
      <div>
        <div className={styles.header}>Go to:</div>
        <ul>
          <GotToLabel keyName="t" name="enant" />
          <GotToLabel keyName="v" name="ersions" />
          <GotToLabel keyName="z" name="oning" />
          <GotToLabel keyName="r" name="equirements" />
          <GotToLabel keyName="f" name="ields" />
          <GotToLabel keyName="$" name="fees" />
          <li>
            <hr />
          </li>
          <GotToLabel keyName="s" name="plash" />
          <GotToLabel keyName="p" name="rojects" />
          <GotToLabel keyName="a" name="pplications" />
        </ul>
      </div>
    </Modal>
  );
};

const DefaultHotKeys = () => {
  useRegisteredHotKey("shift+/", ({ setOverlay }) => setOverlay(Help), {
    section: "Site",
    description: "Open help",
    helpKey: "?",
  });

  useRegisteredHotKey("esc", ({ close }) => close(), {
    alwaysEnabled: true,
    section: "Site",
    description: "Close",
  });

  useRegisteredHotKey("ctrl+q", ({ setEnabled }) => setEnabled(false), {
    section: "Site",
    description: "Disable shortcuts",
  });

  useRegisteredHotKey("g", ({ setOverlay }) => setOverlay(GoTo), {
    section: "Site",
    description: "Go to",
  });

  return null;
};

const MaybeHotKeys = ({ children }) => (
  <HotKeysProvider>
    <SuperuserFeature>
      <DefaultHotKeys />
    </SuperuserFeature>
    {children}
  </HotKeysProvider>
);

export default MaybeHotKeys;
