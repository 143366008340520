import * as ActiveStorage from "@rails/activestorage";

const setCSRFonXHR = (xhr) => {
  const cookieMatch = document.cookie.match("(^|;) ?CSRF-TOKEN=([^;]*)(;|$)");
  const csrfToken = cookieMatch ? decodeURIComponent(cookieMatch[2]) : null;
  if (csrfToken) xhr.setRequestHeader("X-CSRF-Token", csrfToken);
};
const upload = (file) => {
  const delegate = {
    directUploadWillCreateBlobWithXHR: setCSRFonXHR,
    directUploadWillStoreFileWithXHR: setCSRFonXHR,
  };
  const directUpload = new ActiveStorage.DirectUpload(
    file,
    "/rails/active_storage/direct_uploads",
    delegate,
  );
  const promise = new Promise((resolve, reject) => {
    directUpload.create((error, attributes) => {
      if (error) {
        reject(error);
        return;
      }
      resolve(attributes.signed_id);
    });
  });
  return promise;
};

export default upload;
