import * as R from "ramda";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useVersionedClientRef } from "contexts/versionedClient";
import ocClient from "services/ocClient";
import { errorHandler } from "utils/form";
import { arrayWrap, compact } from "utils/func";

export default ({
  basePath: defaultBasePath,
  baseKey,
  dataKey: defaultDataKey,
  versioned = true,
}) => {
  const pathFor = (path, { basePath = defaultBasePath } = {}) =>
    compact([basePath, ...arrayWrap(path)]).join("/");

  const queryKey = (key = []) => compact([...arrayWrap(baseKey), ...arrayWrap(key)]);

  const annotateVersioned = versioned
    ? R.over(R.lensProp("meta"), R.compose(R.assoc("versionedEndpoint", true), R.defaultTo({})))
    : R.identity;

  const useClient = versioned ? useVersionedClientRef : () => ({ current: ocClient });

  const shapePostData = (data, { dataKey = defaultDataKey } = {}) =>
    dataKey ? { [dataKey]: data } : data;

  const useCreate = (path, { onSuccess, dataKey, basePath, ...opts } = {}, cb = R.identity) => {
    const queryClient = useQueryClient();
    const client = useClient();

    return useMutation({
      async mutationFn(record) {
        const postData = shapePostData(record, { dataKey });

        const { data } = await client.current.post(pathFor(path, { basePath }), postData);
        return cb(data);
      },
      onSuccess() {
        queryClient.invalidateQueries(queryKey());
        if (onSuccess) {
          onSuccess();
        }
      },
      ...annotateVersioned(opts),
    });
  };

  const useRead = (path, { key, basePath, ...queryOpts } = {}, cb = R.identity) => {
    const client = useClient();

    return useQuery({
      queryKey: key || queryKey(path),
      async queryFn() {
        const { data } = await client.current.get(pathFor(path, { basePath }));
        return cb(data);
      },
      ...annotateVersioned(queryOpts),
    });
  };

  const useUpdate = (
    path,
    {
      transformData = R.identity,
      onSuccess,
      invalidates,
      setError,
      basePath,
      dataKey,
      ...mutationOpts
    } = {},
    cb = R.identity,
  ) => {
    const queryClient = useQueryClient();
    const client = useClient();

    return useMutation({
      async mutationFn(record) {
        const postData = shapePostData(transformData(record), { dataKey });

        const { data } = await client.current.put(pathFor(path, { basePath }), postData);
        return cb(data);
      },
      onSuccess(data) {
        queryClient.invalidateQueries(queryKey());
        queryClient.setQueryData(queryKey(path), data);
        if (invalidates) {
          queryClient.invalidateQueries(invalidates);
        }

        if (onSuccess) {
          onSuccess(data);
        }
      },
      onError: setError ? errorHandler(setError) : null,
      ...annotateVersioned(mutationOpts),
    });
  };

  const useDestroy = ({ basePath, ...mutationOpts } = {}) => {
    const queryClient = useQueryClient();
    const client = useClient();

    return useMutation({
      async mutationFn(id) {
        return client.current.delete(pathFor(id, { basePath }));
      },
      onSuccess() {
        queryClient.removeQueries(queryKey());
      },
      ...annotateVersioned(mutationOpts),
    });
  };

  return {
    useCreate,
    useRead,
    useUpdate,
    useDestroy,
    queryKey,
    pathFor,
  };
};
