/**
 * This module handles the invalidation for our react-query based state mangement system.
 *
 * Paths added to queries/keys will be used to generate a matching basic invalidation
 * that will invalidate the general/admin data structures
 *
 * In more complex or non-standard cases you can also write each of these
 * parts yourself or customize them.
 *
 * Usage:
 *   import invalidations from "queries/invalidations";
 *   invalidations.projects(queryClient, 1);
 *   invalidations.projects(queryClient);  // all projects
 *
 * This covers use cases like:
 *   invalidate root data for admin/general.
 *   invalidate one thing for admin/general.
 *   share complex invalidation logic between call sites (both admin/general)
 */

import keys from "queries/keys";

// exclude admin routes from root paths
const { admin, ...generalKeys } = keys;

// basic invalidations that invalidate admin & general paths,
// they take the queryClient followed by the same args as the path functions
// and are generated for every query key
const basicInvalidations = {};
Object.entries(generalKeys).forEach(([type, pathFn]) => {
  basicInvalidations[type] = (queryClient, ...args) => {
    queryClient.invalidateQueries(pathFn(...args));
    queryClient.invalidateQueries(admin[type](...args));
  };
});
const invalidations = { ...basicInvalidations };

// customized invalidations for cases where we always want to invalidate related models
// they can use the basic invalidations so that both the genearal/admin data structures
// are invalidated
invalidations.requirementApplicationReviews = (queryClient, projectID, raID) => {
  queryClient.invalidateQueries(invalidations.projects(queryClient, projectID));
  queryClient.invalidateQueries(
    basicInvalidations.requirementApplications(queryClient, projectID, raID),
  );
  queryClient.invalidateQueries(
    basicInvalidations.requirementApplicationReviews(queryClient, projectID, raID),
  );
};

// More complext invalidations which are based around specific but shared use cases
// (you don't meed to always move validation logic here unless you think it should be shared)
// Each of these cases should be documented to encourage reuse.

export default invalidations;
