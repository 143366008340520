import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { onlyUpdateForKeys } from "recompose";

import ErrorBoundary from "components/ErrorBoundary";
import Loader from "components/Loader";
import FormField from "components/project_form/Field";
import withInspectable from "components/utilities/withInspectable";
import { withAnswerContextConsumer } from "containers/withAnswerContext";
import { useCurrentTenant } from "queries/config/tenants";
import { useFieldWithChildren } from "queries/fields";
import { isCalculated } from "reducers/fields";
import { selectTenant } from "reducers/tenant";
import { selectLocale } from "reducers/ui";
import { hoc } from "utils/hoc";

const QueryField = ({ Component, field: { id: fieldID }, required, ...props }) => {
  const { data: field } = useFieldWithChildren(fieldID);
  const { data: tenant } = useCurrentTenant();

  if (!tenant || !field) {
    return <Loader component />;
  }

  if (field?.calculated) {
    return null;
  }

  if (required !== undefined) {
    field.required = required;
  }

  return (
    <ErrorBoundary>
      <Component tenant={tenant} field={field} locale="en" {...props} />
    </ErrorBoundary>
  );
};

const ReduxField = ({ Component, ...props }) => {
  const tenant = useSelector(selectTenant);
  const locale = useSelector(selectLocale);

  return (
    <ErrorBoundary>
      <Component tenant={tenant} locale={locale} {...props} />
    </ErrorBoundary>
  );
};

const ConnectedField = ({ Component, field, answerContext, behaviorType, ...props }) => {
  const ContextContainer = answerContext.type === "REDUX" ? ReduxField : QueryField;
  const isLocked = answerContext?.record?.locked_field_ids?.includes(field.id);

  return (
    <ContextContainer
      Component={Component}
      field={field}
      isLocked={isLocked}
      identifier={field.identifier}
      answerContext={answerContext}
      {...props}
      behaviorType={
        behaviorType !== "issued" && (isCalculated(field) || isLocked) ? "disabled" : behaviorType
      }
    />
  );
};
const withConnection = hoc(ConnectedField);

export default R.compose(
  withAnswerContextConsumer,
  withConnection,
  withInspectable,
  onlyUpdateForKeys(["answerContext", "searchPristine", "behaviorType", "scope"]),
)(FormField);
