import React, { useCallback, useContext, useEffect, useMemo } from "react";
import * as R from "ramda";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { setCurrentUser } from "actions";
import ErrorPage from "components/ErrorPage";
import { selectSessionCacheBustingKey } from "reducers/session";
import {
  isManager,
  isOktaUser,
  isSuper,
  isSuperuser,
  requiresTacAcceptance,
} from "selectors/users";
import analytics from "services/analytics";
import ocClient from "services/ocClient";
import { hoc } from "utils/hoc";

export const SessionContext = React.createContext();

/**
 * @typedef PermissionsTree
 * @type {Object<string, PermissionsTree>}
 * @property {boolean} _any - value is true if anything contained within in this tree is true
 */

/**
 * @typedef SessionContextValue
 * @type {object}
 * @property {User} user - the user object
 * @property {boolean} isLoading - the /session request is loading
 * @property {boolean} isLoaded - the /session request completed successfully
 * @property {boolean} isLoggedIn - this is a real user (ie. not a guest user)
 * @property {boolean} isSuper - the user has one of the roles "superuser" or "superadmin"
 * @property {boolean} isSuperuser - the user has the specific role "superuser"
 * @property {boolean} isOktaUser - the user logged in via Okta
 * @property {boolean} isManager - the user has the specific role "manager"
 * @property {boolean} canAdmin - the user's permissions include something under the "admin" tree
 * @property {boolean} canConfig - the user's permissions include something under the "config" tree
 * @property {function} logout
 * @property {boolean} accela_connected
 * @property {string} pusher_channel
 * @property {boolean} mustAcceptTerms - true if the terms and conditions need to be accepted
 * @property {PermissionsTree} permissions
 * @property {Object} query
 * @property {function} query.invalidate - trigger the react-query to invalidate the session data (causing a refetch)
 * @property {function} query.setData
 */

const hasPermission = R.curryN(2, (path, perms) =>
  R.compose(R.when(R.is(Object), R.prop("_any")), R.pathOr(false, path), R.defaultTo({}))(perms),
);

const getSession = async () => {
  // console.debug("getSession()...");
  const { data } = await ocClient.get("/session");
  // console.debug("getSession() =>", data);
  return data;
};
const queryKey = "session";

export const SessionProvider = ({ children }) => {
  // console.debug("SessionProvider.render()");
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const {
    data: { pusher_channel, user: { accela_connected, permissions = {}, ...user } = {} } = {},
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: getSession,
    onSuccess: ({ user }) => {
      // IMPORTANT: this dispatch is a signal back into sagas that we would have completed the session fetch and CSRF update; it must happen after CSRF is set
      if (user) dispatch(setCurrentUser(user));
    },
  });

  const invalidate = useCallback(
    (reason) => {
      console.debug("SessionProvider.invalidate", reason);
      return refetch(); // trigger refetch immediately and return its promise for downstream awaits
    },
    [refetch],
  );

  const setData = useCallback(
    (sessionData) => {
      queryClient.setQueryData(queryKey, sessionData);
    },
    [queryClient],
  );

  const logout = useCallback(() => {
    window.location.replace("/logout");
  }, []);

  const cacheBustingKey = useSelector(selectSessionCacheBustingKey); // NOTE(rtlong): this is a temporary stop-gap measure to support the Auth sagas being able to trigger this code to refetch. After no saga depends on dispatching fetchSession/FETCH_SESSION, we can eliminate this
  useEffect(() => {
    invalidate(cacheBustingKey ? "Redux FETCH_SESSION" : "Initial");
  }, [invalidate, cacheBustingKey]);

  useEffect(() => {
    analytics.alias(user.id);
    analytics.identify();
  }, [user.id]);

  const session = useMemo(
    () => ({
      user,
      logout,
      accela_connected,
      pusher_channel,
      permissions,
      isLoading,
      isLoaded: isSuccess,
      isLoggedIn: isSuccess && !user.is_guest,
      isSuperuser: isSuperuser(user),
      isSuper: isSuper(user),
      isOktaUser: isOktaUser(user),
      isManager: isManager(user),
      mustAcceptTerms: isSuccess && requiresTacAcceptance(user),

      canAdmin: hasPermission(["admin"], permissions),
      canConfig: hasPermission(["config"], permissions),
      query: {
        invalidate,
        setData,
        refetch,
      },
    }),
    [
      user,
      logout,
      accela_connected,
      pusher_channel,
      permissions,
      isLoading,
      isSuccess,
      invalidate,
      setData,
      refetch,
    ],
  );

  if (isError) return <ErrorPage />;
  return (
    <SessionContext.Provider value={session} children={children} displayName="SessionProvider" />
  );
};

/**
 * useSession hook pulls the value from the SessionContext
 *
 * @public
 * @returns {SessionContextValue}
 */
export const useSession = () => useContext(SessionContext);

export const useIsPermittedTo = (permissionKey) => {
  const { permissions } = useSession();
  return hasPermission(permissionKey.split("."), permissions);
};

// temporary solution for refactoring all session info out of the Redux state without having to completely refactor any container that depends on selectCurrentUser/etc.
const WithCurrentUserFromSession = ({ Component, ...props }) => {
  const { user } = useSession();
  return <Component currentUser={user} {...props} />;
};
export const withCurrentUserFromSession = hoc(WithCurrentUserFromSession);
