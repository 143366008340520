import * as R from "ramda";

import resourceOperations from "../resourceOperations";

const operation = resourceOperations({
  basePath: "/api/admin/changesets",
  baseKey: ["changesets"],
  dataKey: "changeset",
});

export const useChangeset = (id, opts = {}) =>
  operation.useRead(id, R.assoc("basePath", "/api/changesets", opts));
export const useProjectChangesets = (projectID, opts = {}) =>
  operation.useRead(`?project_id=${projectID}`, {
    ...opts,
    key: operation.queryKey(["forProject", projectID]),
  });

// TODO: don't want to clear the cache here, since it returns an incomplete answer set?
export const useUpdateChangeset = (id, opts = {}) => operation.useUpdate(id, opts);
export const useRevertChangeset = (id, opts = {}) => operation.useUpdate([id, "revert"], opts);
